import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
const initialState = {
    status: 'idle',
    movementsStatus: 'idle',
    branchCashboxes: [],
    branchCashboxMovements: [],
    branchCashbox: null,
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
    movementsMeta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0
    },
    branchCashboxAmount: 0,
};
export const getBranchCashboxes = createAsyncThunk('branchCashboxes/getBranchCashboxes', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', isPaging = true, }) => {
    let data;
    const queryParams = {
        search: {
            name: filterName,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/branchCashboxes', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getBranchCashboxMovements = createAsyncThunk('branchCashboxes/getBranchCashboxMovements', async ({ id, page, rowsPerPage, orderBy = 'createdAt', order = 'desc', isPaging = true, }) => {
    let data;
    const queryParams = {
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL(`/branchCashboxes/${id}/movements`, queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createBranchCashbox = createAsyncThunk('branchCashboxes/createBranchCashbox', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/branchCashboxes`, { ...Data });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const getBranchCashbox = createAsyncThunk('branchCashboxes/getBranchCashbox', async (id, thunkAPI) => {
    let data;
    try {
        const response = await axios.get(`/branchCashboxes/${id}`);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(setBranchCashboxAmount(data.data.amount));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editBranchCashbox = createAsyncThunk('branchCashboxes/editBranchCashbox', async ({ id, values }) => {
    let data;
    try {
        const response = await axios.put(`/branchCashboxes/${id}`, {
            ...values,
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const linkInvoices = createAsyncThunk('branchCashboxes/linkInvoices', async ({ id, invoices }) => {
    let data;
    try {
        const response = await axios.put(`/branchCashboxes/${id}/movements/customerTransactions`, {
            invoices,
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createMovement = createAsyncThunk('branchCashboxes/createMovement', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.put(`/branchCashboxes/${id}/movements/cashOnHand`, {
            ...Data,
        });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(addToBranchCashboxAmount(Data.movement.toUpperCase() === 'IN' ? Data.amount : -Data.amount));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteBranchCashbox = createAsyncThunk('branchCashboxes/deleteBranchCashbox', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/branchCashboxes/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getBranchCashboxes({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyBranchCashboxes = createAsyncThunk('branchCashboxes/deleteManyBranchCashboxes', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/branchCashboxes/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBranchCashboxes({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'branchCashbox',
    initialState,
    reducers: {
        setBranchCashboxAmount(state, action) {
            state.branchCashboxAmount = Number(Number.parseFloat(action.payload).toFixed(3));
        },
        addToBranchCashboxAmount(state, action) {
            state.branchCashboxAmount = Number(Number.parseFloat(action.payload + state.branchCashboxAmount).toFixed(3));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranchCashboxMovements.pending, (state) => {
            state.movementsStatus = 'loading';
        })
            .addCase(getBranchCashboxMovements.fulfilled, (state, action) => {
            state.movementsStatus = 'succeeded';
            state.branchCashboxMovements = action.payload.data;
            state.movementsMeta = action.payload.meta;
        })
            .addCase(getBranchCashboxMovements.rejected, (state, action) => {
            state.movementsStatus = 'failed';
        })
            .addCase(getBranchCashboxes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBranchCashboxes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.branchCashboxes = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getBranchCashboxes.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBranchCashbox.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBranchCashbox.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.branchCashbox = action.payload.data;
        })
            .addCase(getBranchCashbox.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
export const { setBranchCashboxAmount, addToBranchCashboxAmount } = slice.actions;
// Reducer
export const reducer = slice.reducer;
export default slice;
